import styled from '@emotion/styled'
import { Row as DefaultRow, Col as DefaultCol } from '../UI/Grid'

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: 64px 16px;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 48px 16px;
  }
`

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`
export const Title = styled.h2`
  font-size: 2rem;
  line-height: 1.4;

  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  span {
    color: ${({ theme }) => theme.colors.orange};
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

export const Text = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2rem;

  padding: 32px 0;

  h3 {
    padding: 48px 0 24px 0;
  }
`

export const Col = styled(DefaultCol)`
  max-width: calc(100% / 2);
  padding-left: ${({ hasPaddingLeft }) => (hasPaddingLeft ? '24px' : 0)};
  padding-right: ${({ hasPaddingRight }) => (hasPaddingRight ? '24px' : 0)};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: 100%;
    padding: 0;
    :not(:first-of-type) {
      margin-top: 32px;
    }
  }
`

export const Row = styled(DefaultRow)`
  justify-content: space-between;
`

export const Image = styled.img`
  width: 100%;
  border-radius: 4px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-height: 100%;
    width: 100%;
  }
`
