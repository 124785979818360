import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import HeroWithImage from '../components/shared/HeroWithImage'

import BeforePreFooter from '../components/shared/BeforePreFooter'
import BengalPage from '../components/BengalPage'
import i18n from '../../config/i18n'

const Bengal = ({ data: { bengal }, pageContext: { locale }, location }) => {
  const { data } = bengal
  const {
    hero_title: heroTitle,
    hero_image: heroImage,
    final_section_title: title,
    final_section_description: description,
    final_section_link_text: linkText,
    final_section_link_url: url,
    final_section_image: image,
  } = data

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} banner={heroImage.url} title={i18n[locale].seo.bengal.title} />
      <HeroWithImage isBengalPage src={heroImage.url} title={heroTitle} />
      <BengalPage data={data} />
      <BeforePreFooter title={title} description={description} linkText={linkText} url={url} image={image.url} />
    </>
  )
}

export default Bengal

Bengal.propTypes = {
  data: PropTypes.shape({
    bengal: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query bengalQuery($locale: String!) {
    bengal: prismicBengal(lang: { eq: $locale }) {
      data {
        hero_title
        hero_image {
          url
        }
        title
        first_section_text {
          html
        }
        second_section_text {
          html
        }
        third_section_text {
          html
        }
        fourth_section_text {
          html
        }
        image_left {
          url
        }
        image_right {
          url
        }
        final_section_title
        final_section_image {
          url
        }
        final_section_link_url
        final_section_link_text
        final_section_description
      }
    }
  }
`
