import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const BengalPage = ({ data }) => {
  const {
    title,
    first_section_text: firstText,
    second_section_text: secondText,
    third_section_text: thirdText,
    fourth_section_text: fourthText,
    image_left: imageLeft,
    image_right: imageRight,
  } = data

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.Title>{title}</S.Title>
        <S.Row>
          <S.Col>
            <S.Text dangerouslySetInnerHTML={{ __html: firstText.html }} />
          </S.Col>
          <S.Col hasPaddingLeft>
            <S.Image src={imageRight.url} alt="Bengal Cat" />
          </S.Col>
        </S.Row>
        <S.Row>
          <S.Text dangerouslySetInnerHTML={{ __html: secondText.html }} />
        </S.Row>
        <S.Row>
          <S.Col hasPaddingRight>
            <S.Image src={imageLeft.url} alt="Bengal Cat" />
          </S.Col>
          <S.Col>
            <S.Text dangerouslySetInnerHTML={{ __html: thirdText.html }} />
          </S.Col>
        </S.Row>
        <S.Row>
          <S.Text dangerouslySetInnerHTML={{ __html: fourthText.html }} />
        </S.Row>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

BengalPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
}
export default BengalPage
